<template>
  <Card title="新增用户">
    <a-tabs v-model="selectedKey">
      <a-tab-pane key="1" tab="基本信息">
        <a-form
          :form="form"
          @submit="handleSubmit"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
          :colon="false"
        >
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="登录账号">
                <a-input
                  v-decorator="[
                    'code',
                    {
                      rules: [{ required: true, message: '请输入' }],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="姓名">
                <a-input
                  v-decorator="[
                    'name',
                    {
                      rules: [{ required: true, message: '请输入' }],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="手机号码">
                <a-input
                  v-decorator="[
                    'mobile',
                    {
                      rules: [{ required: required, message: '请输入' }],
                    },
                  ]"
                />
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="性别">
                <a-radio-group
                  v-decorator="[
                    'sex',
                    {
                      rules: [{ required: true, message: '请选择' }],
                    },
                  ]"
                >
                  <a-radio
                    v-for="item in genderList"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="用户状态">
                <a-radio-group
                  v-decorator="[
                    'status',
                    {
                      rules: [{ required: true, message: '请选择' }],
                    },
                  ]"
                >
                  <a-radio
                    v-for="item in statusList"
                    :key="item.value"
                    :value="item.value"
                  >{{ item.name }}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>

          <div class="center">
            <a-space>
              <a-button @click="cancel">关闭</a-button>
              <a-button html-type="submit" type="primary">下一步</a-button>
            </a-space>
          </div>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="分配角色">
        <a-table
          bordered
          :dataSource="roleList"
          rowKey="id"
          :row-selection="{
            selectedRowKeys: selectedRoleKeys,
            onChange: onSelectRoleChange,
          }"
          :pagination="false"
        >
          <a-table-column key="code" title="编码" data-index="code" />
          <a-table-column key="name" title="名称" data-index="name" />
          <a-table-column key="remarks" title="描述" data-index="remarks" />
        </a-table>
        <div class="center" style="margin-top: 16px">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button @click="save" type="primary">保存</a-button>
          </a-space>
        </div>
      </a-tab-pane>
    </a-tabs>
  </Card>
</template>

<script>
import { add } from "@/api/setting/user";
import { fetchList as fetchRoleList } from "@/api/setting/role";
import { mapGetters } from "vuex";

import encrypt from "@/utils/encrypt";
export default {
  data() {
    return {
      selectedKey: "1",
      form: this.$form.createForm(this),
      roleList: [],
      selectedRoleKeys: [],

      dataPermissionList: [],
      activeIndex: null,

      dataSource: [],
      total: 0,
      targetKeys: [], // 穿梭框右侧内容

      selectedDataPermission: [], // 获取到用户已经选择了所有数据权限，当左侧菜单变化时，把已选中的默认渲染上去

      required: true, // 默认必填
    };
  },

  mounted() {
    fetchRoleList({
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.roleList = Object.freeze(res.list);
      }
    });
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    genderList() {
      return this.findDataDict("gender");
    },
    statusList() {
      return this.findDataDict("status");
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err) => {
        if (!err) {
          this.selectedKey = "2";
        }
      });
    },

    onSelectRoleChange(keys) {
      this.selectedRoleKeys = keys;
    },

    save() {
      this.form.validateFields((err, values) => {
        if (!err) {
          add({
            ...values,
            roles: this.selectedRoleKeys,
            password: encrypt.encrypt("123456"),
          }).then(() => {
            this.cancel();
          });
        } else {
          this.$message.error("请填写用户基本信息");
          return;
        }
      });
    },

    onChange(nextTargetKeys) {
      this.targetKeys = nextTargetKeys;
    },

    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
</style>
