var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":"新增用户"}},[_c('a-tabs',{model:{value:(_vm.selectedKey),callback:function ($$v) {_vm.selectedKey=$$v},expression:"selectedKey"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"基本信息"}},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"登录账号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'code',
                  {
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]),expression:"[\n                  'code',\n                  {\n                    rules: [{ required: true, message: '请输入' }],\n                  },\n                ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]),expression:"[\n                  'name',\n                  {\n                    rules: [{ required: true, message: '请输入' }],\n                  },\n                ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'mobile',
                  {
                    rules: [{ required: _vm.required, message: '请输入' }],
                  },
                ]),expression:"[\n                  'mobile',\n                  {\n                    rules: [{ required: required, message: '请输入' }],\n                  },\n                ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'sex',
                  {
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]),expression:"[\n                  'sex',\n                  {\n                    rules: [{ required: true, message: '请选择' }],\n                  },\n                ]"}]},_vm._l((_vm.genderList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"用户状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'status',
                  {
                    rules: [{ required: true, message: '请选择' }],
                  },
                ]),expression:"[\n                  'status',\n                  {\n                    rules: [{ required: true, message: '请选择' }],\n                  },\n                ]"}]},_vm._l((_vm.statusList),function(item){return _c('a-radio',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v("下一步")])],1)],1)],1)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":"分配角色"}},[_c('a-table',{attrs:{"bordered":"","dataSource":_vm.roleList,"rowKey":"id","row-selection":{
          selectedRowKeys: _vm.selectedRoleKeys,
          onChange: _vm.onSelectRoleChange,
        },"pagination":false}},[_c('a-table-column',{key:"code",attrs:{"title":"编码","data-index":"code"}}),_c('a-table-column',{key:"name",attrs:{"title":"名称","data-index":"name"}}),_c('a-table-column',{key:"remarks",attrs:{"title":"描述","data-index":"remarks"}})],1),_c('div',{staticClass:"center",staticStyle:{"margin-top":"16px"}},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v("保存")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }